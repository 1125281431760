





















































































































































































































































































































































































































































































































































































































































































































import { AppProvider } from '@/app-providers'
import { alertController } from '@/components/alert/alert-controller'
import Countdown from '@/components/countdown.vue'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Inject, Provide, Ref, Vue, Watch } from 'vue-property-decorator'
import { IdoPoolDetailViewModel } from '../viewmodels/ido-pool-detail-viewmodel'
@Observer
@Component({
  components: {
    'pool-tier-item': () => import('../components/pool-tier-item.vue'),
    'countdown-pool-state': () => import('../components/countdown-pool-state.vue'),
    'ido-pool-swap': () => import('./ido-pool-swap.vue'),
    'invest-success-dialog': () => import('../components/invest-success.vue'),
    'allocation-dialog': () => import('../components/allocation-dialog.vue'),
    'hover-image': () => import('@/modules/market/components/hover-image.vue'),
    'market-sale-dialog': () => import('@/modules/market/components/market-sale-dialog.vue'),
    'confirm-sale-dialog': () => import('@/modules/market/components/confirm-sale-dialog.vue'),
    'transfer-dialog': () => import('@/modules/market/components/transfer-dialog.vue'),
    'pool-countdown': () => import('@/components/pool-countdown.vue'),
    'description-markdown': () => import('../components/description-markdown.vue'),
    'hover-image-small': () => import('@/modules/market/components/hover-image-small.vue'),
    Countdown,
  },
})
export default class IdoPoolDetail extends Vue {
  @Inject() providers!: AppProvider
  @Provide() vm = new IdoPoolDetailViewModel()
  @Ref('marketSaleDialog') marketSaleDialog: any
  @Ref('confirmSaleDialog') confirmSaleDialog: any
  @Ref('transferDialog') transferDialog: any
  walletStore = walletStore
  vestingScheduleHeaders = [
    { text: '#', value: 'id', sortable: false, width: '20%', align: 'center' },
    { text: 'Date', value: 'date', sortable: false, width: '40%', align: 'center' },
    { text: 'Vesting Amount', value: 'percentage', sortable: false, width: '40%', align: 'center' },
  ]
  @Watch('$route.params.poolid', { immediate: true }) async onPoolIdChanged(val: string) {
    if (val) {
      this.vm.loadingTable = true
      await this.vm.loadPool(val)
      this.vm.loadingTable = false
    }
  }
  async joinPool() {
    if (this.vm.isInWhitelisted) {
      if (this.vm.allowSwap) {
        const dialog = this.$refs.swapDialog as any
        const ok = await dialog.open()
        if (ok) {
          await this.vm.loadUserInfo(true)
          this.vm.setInvestSuccessDialog(true)
        }
      }
    } else {
      alertController.info('Whitelist error', 'You are not in this project whitelist')
    }
  }

  async createOrder(keyInfo, poolStore) {
    if (await this.marketSaleDialog.open(keyInfo, poolStore)) {
      if (await this.confirmSaleDialog.open()) this.vm.loadUserInfo(true)
    }
  }

  async transferNftCard(keyInfo, poolStore) {
    const ok = await this.transferDialog.open(keyInfo, poolStore)
    if (ok === true) {
      this.vm.loadUserInfo(true)
    }
  }

  joinWhitelist() {
    if (this.vm.forceWhitelist) {
      this.$router.push(`${this.vm.forceWhitelist}/whitelist`)
    } else this.$router.push(`${this.vm.pool?.unicodeName}/whitelist`)
  }

  destroyed() {
    this.vm.destroy()
  }
}
