































































































import { IdoPoolDetailViewModel } from '@/modules/ido/viewmodels/ido-pool-detail-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Vue, Inject, Prop } from 'vue-property-decorator'
import { IdoApplyViewModel } from '../modules/ido/viewmodels/ido-apply-viewmodel'

@Observer
@Component({
  components: {},
})
export default class StakingLPDialog extends Vue {
  @Prop() vm!: IdoApplyViewModel | IdoPoolDetailViewModel
  @Prop({ default: false }) disableBorder?: boolean
  @Prop({ default: false }) isTransparentBg?: boolean
  @Prop({ default: false }) isSmallText?: boolean
}
